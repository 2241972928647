*,html {
  font-family: arial;
  margin:0;
}
.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.acontainer {
  
  margin: auto;
  width: 50%;
  height: 50%;
  text-align: center;
  
}
.bcontainer {
  height: 50%;
  display: flex;
  
}
.ccontainer {
  height: 30vh;
  line-height: 30vh;
  
}

button {
  height: 100%;
  width: 100%;
  
}

.bigtext {
  font-size: 70px;
}